import { signOut } from 'firebase/auth';
import { collection, doc, getDocs, query, setDoc, updateDoc, where } from 'firebase/firestore';
import moment from 'moment';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import './layout.css';
import logo from '@/assets/logo.jpg';
import { useAuth } from '@/lib/auth';
import { auth, db } from '@/lib/firestore';

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [messages, setMessages] = React.useState([]);
  const [activeLink, setActiveLink] = React.useState('');
  const logoutUser = () => {
    logout();
    signOut(auth);
    navigate('/auth/login');
  };

  const handleDate = (dates: any) => {
    // Convert the given date to milliseconds
    const givenDateMilliseconds = dates.seconds * 1000 + Math.round(dates.nanoseconds / 1e6);

    // Get the current date
    const currentDate = moment();

    // Calculate the difference between the current date and the given date
    const timeDifference = moment.duration(currentDate.diff(moment(givenDateMilliseconds)));

    const days = timeDifference.days();
    const hours = timeDifference.hours();
    const minutes = timeDifference.minutes();
    const years = timeDifference.years();

    if (years) {
      return `${years} years ago`;
    } else if (days) {
      return `${days} days ago`;
    } else if (hours) {
      return `${hours} hours ago`;
    } else {
      return `${minutes} min ago`;
    }
  };

  // const id = '0iKMJeQwerwcvbkdhjkyeeeeq';

  // const values = {
  //   userId: `sdasdasdasdasdasd`,
  //   type: 'userGenerated',
  //   isRead: false,
  //   content: 'asdasdasdasdasd',
  //   title: 'dasdasdasd',
  //   data: {
  //     id: '0iKMJeQ3PDuuovxByexa33',
  //   },
  //   id: `${id}`,
  // };
  // const addMessages = async () => {
  //   {
  //     await setDoc(doc(db, 'notifications', id), values);
  //   }
  // };

  // React.useEffect(() => {
  //   const fetchMessages = async () => {
  //     await addMessages();
  //   };

  //   fetchMessages();
  // });

  const getMessages = async () => {
    const typesToRetrieve = [
      'userGenerated',
      'influencerRequest',
      'eventAccepted',
      'eventRejected',
      'roomAccepted',
      'roomRejected',
    ];

    const querySnapshot = await getDocs(
      query(
        collection(db, 'notifications'),
        where('isRead', '==', false),
        where('type', 'in', typesToRetrieve)
      )
    );

    const msgList = querySnapshot.docs.map((doc) => {
      const document = doc.data();
      return document;
    });

    setMessages(msgList);
  };
  // const getMessages = async () => {
  //   const msgSnapshot = await getDocs(collection(db, 'notifications'));

  //   const msgList = msgSnapshot.docs.map((doc) => {
  //     const document = doc.data();
  //     return document;
  //   });

  //   setMessages(msgList);
  // };
  React.useEffect(() => {
    getMessages();
  }, []);

  // const newMessageCount = messages.filter((i) => !i.isRead).length ?? 0;
  const newMessageCount = messages.length;

  const handleMsgClick = (id: string) => async () => {
    const noteRef = doc(db, 'notifications', id);
    await updateDoc(noteRef, { isRead: true });
    getMessages();
  };

  return (
    <>
      {/* ======= Header ======= */}
      <header id="header" className="header fixed-top d-flex align-items-center">
        <div className="d-flex align-items-center justify-content-between">
          <a href="index.html" className="logo d-flex align-items-center">
            <img src={logo} className="mainLogoPic" alt="" />
            <span className="d-none d-lg-block"></span>
          </a>
          <i className="bi bi-list toggle-sidebar-btn" />
        </div>
        {/* End Logo */}

        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item dropdown">
              <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                {/* <i className="bi bi-chat-left-text"></i> */}
                <i className="fa-solid fa-bell"></i>
                {newMessageCount > 0 && (
                  <span className="badge bg-success badge-number">{newMessageCount}</span>
                )}
              </a>

              <ul
                className="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages"
                style={{ width: '320px' }}
              >
                {newMessageCount > 0 ? (
                  <>
                    <li
                      className="dropdown-header killAll"
                      onClick={() => navigate('/app/all-notifications')}
                    >
                      {`You have ${newMessageCount} new ${newMessageCount === 1 ? 'message' : 'messages'
                        }`}
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                  </>
                ) : (
                  <>
                    <li className="dropdown-header">No new messages</li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                  </>
                )}

                {messages.slice(0, 5).map((message, index) => (
                  <>
                    <li className="message-item" key={index} onClick={handleMsgClick(message.id)}>
                      <a key={index}>
                        <span className="dot">
                          {!message.isRead && <i className="fa-solid fa-circle"></i>}
                        </span>
                        <div>
                          <p>{message.title}</p>
                        </div>
                        <div>
                          <p className="headDates">
                            {/* {message.date
                              ? new Date(message.date?.seconds * 1000).toLocaleTimeString()
                              : 'N/A'} */}

                            {handleDate(message.date)}
                          </p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                  </>
                ))}
              </ul>
            </li>
            {/* End Messages Nav */}
            <li className="nav-item dropdown pe-3">
              <a
                className="nav-link nav-profile d-flex align-items-center pe-0"
                href="#"
                data-bs-toggle="dropdown"
              >
                <span className="d-none d-md-block dropdown-toggle ps-2">{user?.name}</span>
              </a>
              <div className="user-role">{user?.role}</div>
              {/* End Profile Iamge Icon */}
              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                {/* <li className="dropdown-header"> <h6>{user?.name}</h6></li>
                 <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>  */}
                <li>
                  <a
                    onClick={() => navigate('/app/profile')}
                    className="dropdown-item d-flex align-items-center makeTextGolden"
                    href="#"
                  >
                    <i className="bi bi-person-circle" />
                    <span className="sgnot">Profile</span>
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => navigate('/app/change-password')}
                    className="dropdown-item d-flex align-items-center makeTextGolden"
                    href="#"
                  >
                    <i className="bi bi-file-lock2" />
                    <span className="sgnot">Change Password</span>
                  </a>
                </li>

                <li>
                  <a
                    onClick={logoutUser}
                    className="dropdown-item d-flex align-items-center makeTextGolden"
                    href="#"
                  >
                    <i className="bi bi-box-arrow-right" />
                    <span className="sgnot">Sign Out</span>
                  </a>
                </li>
              </ul>
              {/* End Profile Dropdown Items */}
            </li>
            {/* End Profile Nav */}
          </ul>
        </nav>
        {/* End Icons Navigation */}
      </header>
      {/* End Header */}
      {/* ======= Sidebar ======= */}

      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className={`nav-item ${activeLink == '/app' ? 'active' : ''}`}>
            <Link className="nav-link" to="/app" onClick={() => setActiveLink('/app')}>
              <i className="fa-solid fa-list"></i>
              <span>Dashboard</span>
            </Link>
          </li>
          {user?.role !== 'Professional' && (
            <li className={`nav-item ${activeLink == '/app/users' ? 'active' : ''}`}>
              <Link
                className="nav-link collapsed"
                to="/app/users"
                onClick={() => setActiveLink('/app/users')}
              >
                <i className="fa-solid fa-users"></i>

                <span>Users</span>
              </Link>
            </li>
          )}
          <li className={`nav-item ${activeLink == '/app/events' ? 'active' : ''}`}>
            <Link
              className="nav-link collapsed"
              to="/app/events"
              onClick={() => setActiveLink('/app/events')}
            >
              <i className="fa-solid fa-calendar-check"></i>
              <span>Events</span>
            </Link>
          </li>
          <li className={`nav-item ${activeLink == '/app/rooms' ? 'active' : ''}`}>
            <Link
              className="nav-link collapsed"
              to="/app/rooms"
              onClick={() => setActiveLink('/app/rooms')}
            >
              <i className="fa-solid fa-box-open"></i>
              <span>Rooms</span>
            </Link>
          </li>

          {user?.role !== 'Professional' && (
            <li className={`nav-item ${activeLink == '/app/subscription-list' ? 'active' : ''}`}>
              <Link
                className="nav-link collapsed"
                to="/app/subscription-list"
                onClick={() => setActiveLink('/app/subscription-list')}
              >
                <i className="fa-solid fa-user-secret"></i>
                <span>Subscription</span>
              </Link>
            </li>
          )}

          {user?.role !== 'Professional' && (
            <li className={`nav-item ${activeLink == '/app/influencer-requests' ? 'active' : ''}`}>
              <Link
                className="nav-link collapsed"
                to="/app/influencer-requests"
                onClick={() => setActiveLink('/app/influencer-requests')}
              >
                <i className="fa-solid fa-user-secret"></i>
                <span>Influencer Requests</span>
              </Link>
            </li>
          )}
          {user?.role !== 'Professional' && (
            <li className={`nav-item ${activeLink == '/app/professionals' ? 'active' : ''}`}>
              <Link
                className="nav-link collapsed"
                to="/app/professionals"
                onClick={() => setActiveLink('/app/professionals')}
              >
                <i className="fa-solid fa-user-secret"></i>
                <span>Professionals</span>
              </Link>
            </li>
          )}
          <li className={`nav-item ${activeLink == '/app/all-notifications' ? 'active' : ''}`}>
            <Link
              className="nav-link collapsed"
              to="/app/all-notifications"
              onClick={() => setActiveLink('/app/all-notifications')}
            >
              <i className="fa-solid fa-envelope-open-text"></i>
              <span>Notifications</span>
            </Link>
          </li>
          {user?.role !== 'Professional' && (
            <li className={`nav-item ${activeLink == '/app/all-notifications' ? 'active' : ''}`}>
              <Link
                className="nav-link collapsed"
                to="/app/city"
                onClick={() => setActiveLink('/app/city')}
              >
                <i className="fa-solid fa-envelope-open-text"></i>
                <span>Cities</span>
              </Link>
            </li>)}
        </ul>
      </aside>

      {/* End Sidebar*/}
      <main id="main" className="main">
        {children}
      </main>
      {/* End #main */}
    </>
  );
};
