import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { MainLayout } from '@/components/Layout';
import EventDetail from '@/features/events/routes/EventDetail';
import { InfluencerRequest } from '@/features/influencerRequest';
import { NotFound } from '@/features/misc';
import { AllNotification } from '@/features/notification/routes/AllNotification';
import { AddRoom, Rooms } from '@/features/rooms';
import RoomDetail from '@/features/rooms/routes/RoomDetail';
import { AddSubscription, Subscription } from '@/features/subscription';
import SubscriptionDetail from '@/features/subscription/routes/SubscriptionDetail';
import { lazyImport } from '@/utils/lazyImport';

const { Dashboard } = lazyImport(() => import('@/features/misc'), 'Dashboard');
const { Users } = lazyImport(() => import('@/features/users'), 'Users');
const { AdminProfile } = lazyImport(() => import('@/features/users'), 'AdminProfile');
const { ChangePassword } = lazyImport(() => import('@/features/users'), 'ChangePassword');
const { Profile } = lazyImport(() => import('@/features/users'), 'Profile');
const { Events } = lazyImport(() => import('@/features/events'), 'Events');
const { AddEvent } = lazyImport(() => import('@/features/events'), 'AddEvent');
const { Professionals } = lazyImport(() => import('@/features/professionals'), 'Professionals');
const { AddProfessional } = lazyImport(() => import('@/features/professionals'), 'AddProfessional');
const { City } = lazyImport(() => import('@/features/city'), 'City');
const { AddCity } = lazyImport(() => import('@/features/city'), 'AddCity');

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: '/app',
    element: <App />,
    children: [
      { path: 'users', element: <Users /> },
      { path: 'profile', element: <AdminProfile /> },
      { path: 'change-password', element: <ChangePassword /> },
      { path: 'events', element: <Events /> },
      { path: 'event-detail/:id', element: <EventDetail /> },
      { path: 'room-detail/:id', element: <RoomDetail /> },
      { path: 'event/add-event', element: <AddEvent /> },
      { path: 'event/edit-event/:id', element: <AddEvent /> },
      { path: 'professionals', element: <Professionals /> },
      { path: 'professional/add-professional', element: <AddProfessional /> },
      { path: 'professional/edit-professional/:id', element: <AddProfessional /> },
      { path: 'rooms', element: <Rooms /> },
      { path: 'all-notifications', element: <AllNotification /> },
      { path: 'influencer-requests', element: <InfluencerRequest /> },
      { path: 'subscription-list', element: <Subscription /> },
      { path: 'add-subscription', element: <AddSubscription /> },
      { path: 'edit-subscription/:id', element: <AddSubscription /> },
      { path: 'subscription-detail/:id', element: <SubscriptionDetail /> },
      { path: 'room/add-room', element: <AddRoom /> },
      { path: 'room/edit-room/:id', element: <AddRoom /> },
      { path: 'user/view/:id', element: <Profile /> },
      { path: 'city', element: <City /> },
      { path: 'city/add', element: <AddCity /> },
      { path: '', element: <Dashboard /> },
      { path: '*', element: <NotFound /> },
    ],
  },
];
